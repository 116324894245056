import React from "react";
import StatusForm from "./components/StatusForm";
import { withStatusFormLogic } from "./hoc/StatusFormLogic";

const StatusFormWithLogic = withStatusFormLogic(StatusForm);
const AddStatusPage: React.FC = () => {
  return (
    <>
      <StatusFormWithLogic />
    </>
  );
};

export default AddStatusPage;
