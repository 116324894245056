import React from 'react'
import OrganizationForm from './components/OrganizationForm'
import { withOrganizationFormLogic } from './hoc/OrganizationFormLogic'

const OrgFormWithLogic = withOrganizationFormLogic(OrganizationForm);
const AddOrganizationPage: React.FC = () => {
  return (
    <>
      <OrgFormWithLogic />
    </>
  )
}

export default AddOrganizationPage
