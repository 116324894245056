/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { KTSVG } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";
import { getCSS, getCSSVariableValue } from "../../../assets/ts/_utils";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { Lists } from "../../../../app/modules/widgets/components/Lists";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

type Props = {
  className: string;
};

const ChartsWidget1: React.FC<Props> = ({ className }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [lists, setLists] = useState({
    open: [],
    inprogress: [],
    completed: [],
  });
  const [categories, setCategories] = useState([]);
  const { mode } = useThemeMode();

  function getSum(mode = "open") {
    const sum = lists[mode].reduce((partialSum, a) => partialSum + a, 0);
    return sum;
  }

  function navigateTo(status) {
    switch (status) {
      case "completed":
        navigate("/ticket/completed");
        break;
      case "inprogress":
        navigate("/ticket/inprogress");
        break;
      default:
        navigate("/ticket/open");
    }
  }

  useEffect(() => {
    const getTicketSummary = async () => {
      try {
        setLoading(true);
        const results = await EJKNServiceSingleton.summaryTicket();
        const chartData = results.data.reduce((final: any, current: any) => {
          const key = `${current.year}${
            current.monthNumber >= 10
              ? current.monthNumber
              : "0" + current.monthNumber
          }`;
          if (!final[key]) {
            final[key] = {
              open: 0,
              inprogress: 0,
              completed: 0,
            };
          }
          if (current.status_id === 1) {
            final[key].open += current.data;
          } else if (current.status_id === 6) {
            final[key].completed += current.data;
          } else {
            final[key].inprogress += current.data;
          }

          return final;
        }, {});

        const tempCategories = results.data.reduce(
          (final: any, current: any) => {
            const tempMonth =
              current.month.length > 3
                ? current.month.substring(0, 3)
                : current.month;
            const key = `${tempMonth} ${current.year
              .toString()
              .substring(2, current.year.length)}`;
            if (final.indexOf(key) < 0) {
              final.push(key);
            }
            return final;
          },
          []
        );
        setCategories(tempCategories);
        // setOpenLists(chartData)
        const tempData = {
          open: [],
          inprogress: [],
          completed: [],
        };

        let chartKeys = [];
        Object.keys(chartData).forEach((k) => chartKeys.push(Number(k)));
        chartKeys.sort((a, b) => a - b);
        chartKeys.forEach((k) => {
          tempData.open.push(chartData[k].open);
          tempData.inprogress.push(chartData[k].inprogress);
          tempData.completed.push(chartData[k].completed);
        });

        setLists(tempData);
      } catch (error) {
        ToastrServiceSingleton.error(
          "Failed to fetch summary ticket information!"
        );
      } finally {
        setLoading(false);
      }
    };
    getTicketSummary();
  }, []);
  useEffect(() => {
    const chart = refreshChart();
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, lists, categories]);

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, lists, categories)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        {/* begin::Title */}
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Tickets Logs</span>
        </h3>
        {/* end::Title */}

        {/* begin::Toolbar */}
        <div className="card-toolbar">
          {/* begin::Menu */}
          <a
            href="#"
            onClick={() => {}}
            className="btn btn-sm fw-bold btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_create_app"
          >
            Go to Report
          </a>
          {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button> */}
          <Dropdown1 />
          {/* end::Menu */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      {loading ? (
        <div className="card-body">
          <div className="row box-container">
            <div className="col-sm-12 col-md-4 p-1">
              <Skeleton width={"100%"} height={80} />
            </div>
            <div className="col-sm-12 col-md-4 p-1">
              <Skeleton width={"100%"} height={80} />
            </div>
            <div className="col-sm-12 col-md-4 p-1">
              <Skeleton width={"100%"} height={80} />
            </div>
          </div>
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
              Ticket Charts Year 2022
            </span>
          </h3>
          <Skeleton height={600} width="100%" />
        </div>
      ) : (
        <div className="card-body">
          <div className="row box-container">
            <div className="col-sm-12 col-md-4 p-1">
              <div className="box" onClick={() => navigateTo("open")}>
                <div className="primary main-title">Open Tickets</div>
                <div className="subtitle">{getSum("open")}</div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 p-1">
              <div className="box" onClick={() => navigateTo("completed")}>
                <div className="success main-title">Closed Tickets</div>
                <div className="subtitle">{getSum("completed")}</div>
              </div>
            </div>
            <div
              className="col-sm-12 col-md-4 p-1"
              onClick={() => navigateTo("inprogress")}
            >
              <div className="box">
                <div className="warning main-title">In-Progress Tickets</div>
                <div className="subtitle">{getSum("inprogress")}</div>
              </div>
            </div>
          </div>
          {/* begin::Title */}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
              Ticket Charts Year 2022
            </span>
          </h3>
          {/* end::Title */}
          {/* begin::Chart */}
          <div
            ref={chartRef}
            id="kt_charts_widget_1_chart"
            style={{ height: "600px" }}
          />
          {/* end::Chart */}
        </div>
      )}

      {/* end::Body */}
    </div>
  );
};

export { ChartsWidget1 };

function getChartOptions(height: number, lists, categories): ApexOptions {
  const labelColor = getCSSVariableValue("--kt-gray-500");
  const borderColor = getCSSVariableValue("--kt-gray-200");
  const baseColor = getCSSVariableValue("--kt-primary");
  const secondaryColor = "#50cd89";
  const thirdColor = getCSSVariableValue("--kt-warning");

  return {
    series: [
      {
        name: "Open",
        data: lists.open,
      },
      {
        name: "Closed",
        data: lists.completed,
      },
      {
        name: "In-Progress",
        data: lists.inprogress,
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "bar",
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return val + (val > 1 ? " tickets" : " ticket");
        },
      },
    },
    colors: [baseColor, secondaryColor, thirdColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}
