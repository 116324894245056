import { createAsyncThunk } from "@reduxjs/toolkit";
import { EJKNServiceSingleton } from "../../services/api/ejkns.service";
import { districtErrors, districtSuccess } from "../district/district";
import { login, success, error, setConfigured } from "../global/global";
import { replaceOrganization } from "../organization/organization";
import { replaceRole } from "../roles/roles";
import { replaceState } from "../states/state";
import { zonesErrors, zonesSuccess } from "../zones/zones";

const enableSplashScreen = () => {
  const splashScreen = document.getElementById("splash-screen");
  if (splashScreen) {
    splashScreen.style.setProperty("display", "flex");
  }
};

const disableSplashScreen = () => {
  const splashScreen = document.getElementById("splash-screen");
  if (splashScreen) {
    splashScreen.style.setProperty("display", "none");
  }
};

export const initiateArea = createAsyncThunk(
  "thunk/initiateArea",
  async (data: any, thunkAPI) => {
    try {
      enableSplashScreen();
      const zones = await EJKNServiceSingleton.zoneLists();
      const districts = await EJKNServiceSingleton.districtsLists();
      const roles = await EJKNServiceSingleton.roles();
      const states = await EJKNServiceSingleton.states();
      const organizations = await EJKNServiceSingleton.organizationLists();
      thunkAPI.dispatch(setConfigured(false));
      if (zones) {
        thunkAPI.dispatch(zonesSuccess(zones.data));
      }
      if (districts) {
        thunkAPI.dispatch(districtSuccess(districts.data));
      }
      if (roles) {
        thunkAPI.dispatch(replaceRole(roles.data));
      }
      if (states) {
        thunkAPI.dispatch(replaceState(states.data));
      }

      if (organizations) {
        thunkAPI.dispatch(replaceOrganization(organizations.data));
      }
    } catch (erMsg) {
      thunkAPI.dispatch(zonesErrors(erMsg));
      thunkAPI.dispatch(districtErrors(erMsg));
      return thunkAPI.rejectWithValue("something went wrong");
    } finally {
      disableSplashScreen();
      thunkAPI.dispatch(setConfigured(true));
    }
  }
);
