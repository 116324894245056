/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { MediaUpload } from "../../widgets/components/MediaUpload";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";

type Props = {
  show: boolean;
  onConfirm: (data: any) => {};
  ticketId: number;
  dpData?: any;
  sourceMedia?: any[];
  onClose?: () => void;
};

interface IDropPoint {
  id?: number;
  ticket_id?: number;
  address: string;
  latitude: number;
  longitude: number;
  description: string;
  pilot_id?: number;
  engineer_id?: number;
}

const modalsRoot = document.getElementById("root-modals") || document.body;

const dropPointsSchema = Yup.object().shape({
  latitude: Yup.string().required("Latitude is required!!"),
  longitude: Yup.string().required("Longitude is required!!"),
  description: Yup.string().required("Description is required!!"),
  address: Yup.string().required("Address is required!!"),
});

const DropPointForm = ({
  show,
  onConfirm,
  ticketId,
  dpData = null,
  sourceMedia = [],
  onClose,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const addRef = useRef<any>();
  const descRef = useRef<any>();
  const [coordinateLoading, setCoordinateLoading] = useState(false);
  const [pendingMedia, setPendingMedia] = useState<any>([]);
  const { user } = useSelector(({ global }: any) => global);
  const [dropPointData, setDropPointData] = useState<any>({
    id: null,
    address: "",
    latitude: 0,
    longitude: 0,
    description: "",
    pilot_id: null,
    engineer_id: null,
    ticket_id: null,
  });
  const [media, setMedia] = useState<any>([]);
  const formik = useFormik<IDropPoint>({
    initialValues: dropPointData,
    validationSchema: dropPointsSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      await handleFormSubmit(values);
    },
  });

  async function handleFormSubmit(values: any) {
    let dropPoint: IDropPoint = {
      ...values,
      ticket_id: ticketId,
      pilot_id: user.id,
      engineer_id: user.id,
    };
    let action = "create";
    if (dropPoint.id) action = "update";
    try {
      setLoading(true);

      const data: any = await onConfirm(dropPoint);
      setDropPointData(data);
      if (pendingMedia.length > 0) {
        await uploadPendingMedia(data.id);
      }
      ToastrServiceSingleton.success(`Successfull to ${action} drop point!`);
    } catch (error) {
      ToastrServiceSingleton.error(`Failed to ${action} drop point!`);
    } finally {
      setLoading(false);
    }

    // if (close) handleClose();
  }

  function addMedia() {
    if (media.length < 3)
      setMedia((value: any) => [
        ...value,
        {
          id: value.length + 1,
          latitude: "",
          longitude: "",
          droppoint_id: "",
          file_name: "",
          file_description: "",
          file_size: "",
          file_type: "",
        },
      ]);

    if (!dropPointData.id) {
      setPendingMedia((value: any) => [
        ...value,
        {
          id: value.length + 1,
          file_data: null,
          latitude: "",
          longitude: "",
        },
      ]);
    }
  }

  async function uploadFile(data) {
    const mediaResult = await EJKNServiceSingleton.storeMedia(data);
    return mediaResult;
  }

  async function uploadPendingMedia(id) {
    try {
      const multipleUpload = [];
      pendingMedia.forEach((m) => {
        const formData = new FormData();
        formData.append("file", m.file_data);
        formData.append("droppoint_id", id);
        formData.append("latitude", m.latitude);
        formData.append("longitude", m.longitude);
        multipleUpload.push(uploadFile(formData));
      });

      const results = await Promise.all(multipleUpload);
      ToastrServiceSingleton.success("Successfull upload media!");
      let finalMedia = results.reduce((final, curr) => {
        final.push(curr.data);
        return final;
      }, []);

      setMedia(finalMedia);
      setPendingMedia([]);
    } catch (error) {
      console.log(error, "error");
    }
  }

  async function handleFileUpload(data: any, metadata: any, index: number) {
    const formData = new FormData();
    formData.append("file", data);
    formData.append("droppoint_id", dropPointData.id);
    formData.append("latitude", metadata.latitude);
    formData.append("longitude", metadata.longitude);

    if (dropPointData.id) {
      const mediaResult = await EJKNServiceSingleton.storeMedia(formData);
      const temp = media.map((currValue: any, i: number) => {
        if (i == index) {
          return mediaResult.data;
        }
        return currValue;
      });
      setMedia(temp);
      ToastrServiceSingleton.success("Successfull upload media!");
    } else {
      const temp = pendingMedia.map((currValue: any, i: number) => {
        if (i == index) {
          return {
            id: currValue.id,
            file_data: data,
            latitude: metadata.latitude,
            longitude: metadata.longitude,
          };
        }
        return currValue;
      });

      const tempMedia = media.map((currValue: any, i: number) => {
        if (i == index) {
          return {
            ...currValue,
            latitude: metadata.latitude,
            longitude: metadata.longitude,
          };
        }
        return currValue;
      });
      setMedia(tempMedia);
      setPendingMedia(temp);
    }
  }

  async function applyCoordinate(latitude, longitude) {
    try {
      setCoordinateLoading(true);
      const tempDropPoint = { ...dropPointData, latitude, longitude };
      if (dropPointData && dropPointData.id)
        await handleFormSubmit(tempDropPoint);
      else
        setDropPointData((val) => ({
          ...val,
          latitude,
          longitude,
          description: descRef.current.value,
          address: addRef.current.value,
        }));
    } catch (error) {
      ToastrServiceSingleton.success("Fail to update the coordinate!");
    } finally {
      setCoordinateLoading(false);
    }
  }

  async function deleteMedia(data: any) {
    try {
      if (data.file_name && data.file_name !== "") {
        const deleteMediaResult = await EJKNServiceSingleton.deleteMedia(
          data.id
        );
        ToastrServiceSingleton.success("Successful delete media");
      }

      setMedia((currMedia) => currMedia.filter((m) => m.id !== data.id));
    } catch (error) {
      ToastrServiceSingleton.error("Error to delete media");
    }
  }

  useEffect(() => {
    if (dpData) setDropPointData(dpData);
  }, [dpData]);

  useEffect(() => {
    if (sourceMedia.length > 0) {
      setMedia(sourceMedia);
    }
  }, [sourceMedia]);

  return (
    <form onSubmit={formik.handleSubmit} noValidate className="form">
      <div className="modal-header">
        <h2>{dropPointData.id ? "Update Drop Point" : "New Drop Point"}</h2>
      </div>

      <div className="modal-body py-lg-10 px-lg-10">
        <div className="row mb-6">
          <div className="col-sm-12 col-md-6">
            <label className="col-lg-4 col-form-label required fw-bold fs-6">
              Latitude
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              placeholder="Latitude"
              {...formik.getFieldProps("latitude")}
            />
            {formik.touched.latitude && formik.errors.latitude && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.latitude}</div>
              </div>
            )}
          </div>
          <div className="col-sm-12 col-md-6">
            <label className="col-lg-4 col-form-label required fw-bold fs-6">
              Longitude
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              placeholder="Longitude"
              {...formik.getFieldProps("longitude")}
            />
            {formik.touched.longitude && formik.errors.longitude && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.longitude}</div>
              </div>
            )}
          </div>
          <div className="col-sm-12">
            <label className="col-lg-4 col-form-label required fw-bold fs-6">
              Description
            </label>
            <textarea
              className="form-control form-control-solid mb-8"
              rows={3}
              placeholder="Description"
              {...formik.getFieldProps("description")}
              ref={descRef}
            ></textarea>
            {formik.touched.description && formik.errors.description && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.description}</div>
              </div>
            )}
          </div>
          <div className="col-sm-12">
            <label className="col-lg-4 col-form-label required fw-bold fs-6">
              Address
            </label>
            <textarea
              className="form-control form-control-solid mb-8"
              rows={3}
              placeholder="Address"
              {...formik.getFieldProps("address")}
              ref={addRef}
            ></textarea>
            {formik.touched.address && formik.errors.address && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.address}</div>
              </div>
            )}
          </div>
          {/* {dropPointData.id && ( */}
          <>
            <div className="col-sm-12 p-4">
              <div className="float-right">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={addMedia}
                >
                  Add Media
                </button>
              </div>
            </div>
            {/* <div className="col-sm-12 col-md-4"> */}
            {media.map((m: any, i: number) => (
              <div className="col-sm-12 col-md-4" key={i}>
                <MediaUpload
                  key={i}
                  fileUpload={handleFileUpload}
                  deleteMedia={deleteMedia}
                  mediaIndex={i}
                  media={m}
                />
                {m.id &&
                  m.latitude != "" &&
                  m.longitude != "" &&
                  m.latitude != "N/A" &&
                  m.longitude != "N/A" && (
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => applyCoordinate(m.latitude, m.longitude)}
                      >
                        {!coordinateLoading && "Use this Coordinate"}
                        {coordinateLoading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...{" "}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  )}
              </div>
            ))}
          </>
        </div>
      </div>
      {/* </div> */}
      <div className="modal-footer d-flex justify-content-between py-6 px-9">
        <button type="button" className="btn btn-default" onClick={onClose}>
          <span className="indicator-progress" style={{ display: "block" }}>
            Close
            {/* Please wait...{" "} */}
            {/* <span className="spinner-border spinner-border-sm align-middle ms-2"></span> */}
          </span>
        </button>
        <button type="submit" className="btn btn-primary">
          <span className="indicator-progress" style={{ display: "block" }}>
            {!loading && dropPointData.id
              ? "Update Drop Point"
              : "Create Drop Point"}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
            {/* Please wait...{" "} */}
            {/* <span className="spinner-border spinner-border-sm align-middle ms-2"></span> */}
          </span>
        </button>
      </div>
    </form>
  );
};

export { DropPointForm };
