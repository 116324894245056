/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
    return () => {
      if (root) {
        root.style.height = "auto";
      }
    };
  }, []);

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid h-100">
      {/* begin::Body */}
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1"
        style={{background: "#1E1E2D"}}
      >
        {/* begin::Form */}
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
          {/* begin::Content */}
          <div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
            {/* begin::Logo */}
            <Link to="/" className="mb-12">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/core-data-logo.png")}
                className="h-75px"
              />
            </Link>
            {/* end::Logo */}

            {/* begin::Image */}
            <img
              className="mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
              src={toAbsoluteUrl("/media/misc/auth-screens.png")}
              alt=""
            />
            {/* end::Image */}

            {/* begin::Title */}
            <h1 className="text-white fs-2qx fw-bolder text-center mb-7">
              DataView Application
            </h1>
            {/* end::Title */}

            {/* begin::Text */}
            {/* <div className='text-white fs-base text-center'>
            In this kind of post,{' '}
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              the blogger
            </a>
            introduces a person they’ve interviewed <br /> and provides some background information
            about
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              the interviewee
            </a>
            and their <br /> work following this is a transcript of the interview.
          </div> */}
            {/* end::Text */}
          </div>
          {/* end::Content */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
        // style={{
        //   backgroundImage: `url(${toAbsoluteUrl("/media/misc/auth-bg.png")})`,
        // }}
      >
        <div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
          {/* begin::Form */}
          <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            {/* begin::Wrapper */}
            <div className="w-lg-500px">
              <Outlet />
            </div>
            {/* end::Wrapper */}
          </div>
        </div>
      </div>
      {/* end::Aside */}
    </div>
  );
};

export { AuthLayout };
