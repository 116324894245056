import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { EJKNServiceSingleton } from "../../../services/api/ejkns.service";
import { ArcGIS } from "../../../services/arcgis/map.service";
import "./_mapElementStyle.css";

const arcgis = ArcGIS.getInstance();

const MapElement = () => {
  const viewDiv = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!arcgis.map) {
      arcgis.map = new Map({
        basemap: "satellite",
      });
    }

    if (!arcgis.view) {
      arcgis.view = new MapView({
        container: viewDiv.current as HTMLDivElement,
        map: arcgis.map,
        center: [101.642, 2.9279],
        zoom: 11,
      });
    } else {
      arcgis.view.container = viewDiv.current as HTMLDivElement;
    }

    arcgis.map.add(arcgis.Layer);

    arcgis.view.when(() => {
      arcgis.view.on("click", () => {
        // arrayPoint.push([event.mapPoint.longitude, event.mapPoint.latitude]);
      });
    });

    const handleData = async () => {
      EJKNServiceSingleton.allticketsLists().then((res) => {
        arcgis.initializeTicketLayer(res.data);
      });

      EJKNServiceSingleton.dropPointsMap().then((res) => {
        let features = res.data;
        arcgis.initializeDropPointLayer(features);
      });
    };

    arcgis.view.popup.on("trigger-action", (event) => {
      const popup = arcgis.view.popup;
      const selectedFeature = popup.selectedFeature;
      if (event.action.id === "view-ticket") {
        navigate(`/ticket/detail/${selectedFeature.attributes.OBJECTID}`);
      }
      if (event.action.id === "view-drop-point") {
        arcgis.view.goTo({
          target: selectedFeature,
          zoom: 18,
        });
      }
    });

    handleData();
  }, []);

  const legendClick = () => {
    if (arcgis.LegendWidget) {
      arcgis.LegendWidget.visible = !arcgis.LegendWidget.visible;
    }
  };

  return (
    <>
      <div ref={viewDiv} className="col-md-12 map-container"></div>
      <div className="legend-btn-mobile" onClick={legendClick}>
        <img
          src="https://raw.githubusercontent.com/Esri/calcite-ui-icons/master/icons/legend-24.svg"
          alt="Legend"
        />
      </div>
    </>
  );
};

export default MapElement;
