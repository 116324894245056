import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { EJKNServiceSingleton } from "../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../services/toastr/toastr.service";
import OrganizationTable from "./components/OrganizationTable";

export default function OrganizationLists() {
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);

  function handleDeletionLocally(id: number) {
    setOrganizations((org) => org.filter((o: any) => o.id !== id));
  }

  useEffect(() => {
    const getListOrganizations = async () => {
      try {
        setLoading(true);
        const lists = await EJKNServiceSingleton.organizationLists();
        if (lists.data.length > 0) setOrganizations(lists.data);
      } catch (error) {
        ToastrServiceSingleton.error("Fail to fetch organization lists");
      } finally {
        setLoading(false);
      }
    };
    getListOrganizations();
  }, []);



  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">List of Organization</h3>
        </div>
      </div>
      <div className="card-body border-top p-9">
        {loading ? (
          <>
            <SkeletonTheme>
              {/* <div className="row col-md-12"> */}
              <Skeleton width={"100%"} height={30} />
              <Skeleton count={5} width={"100%"} height={30} />
              {/* </div> */}
            </SkeletonTheme>
          </>
        ) : (
          <OrganizationTable
            organizationsList={organizations}
            handleDeletionLocally={handleDeletionLocally}
          />
        )}
      </div>
    </div>
  );
}
