import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";
import { IRole, roleInitialValues } from "../RoleModel";

export const withRoleFormLogic = (WrappedComponent: any) => {
  return () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] =
      useState<IRole>(roleInitialValues);

    useEffect(() => {
      if (id) {
        const getOrgDetails = async (id: number) => {
          try {
            setLoading(true);
            const data = await EJKNServiceSingleton.roleById(id);
            setInitialValues(data.data);
          } catch (error) {
            ToastrServiceSingleton.error("Failed to fetch role details");
          } finally {
            setLoading(false);
          }
        };
        getOrgDetails(Number(id));
      } else {
        setInitialValues(roleInitialValues);
      }
    }, [id]);

    return (
      <WrappedComponent
        initialValues={initialValues}
        mainLoading={loading}
        onFormSubmit={
          id
            ? (id: number, data: any) =>
                EJKNServiceSingleton.updateRole(id, data)
            : (data: any) => EJKNServiceSingleton.createRole(data)
        }
      />
    );
  };
};
