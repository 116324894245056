/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, AuthPage, useAuth } from "../modules/auth";
import { App } from "../App";
import { useDispatch, useSelector } from "react-redux";
import { initiateArea } from "../../features/thunks/area.thunk";
import { setMyRole } from "../../features/roles/roles";
import { LoaderComponent } from "../pages/components/LoaderComponent";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { user, isLoading } = useSelector((state: any) => state.global);
  const dispatch = useDispatch<any>();
  const [firstTime, setFirstTime] = useState(false);
  const { role } = useSelector(({ roles }: any) => roles);

  useEffect(() => {
    if (user && !firstTime) {
      dispatch(initiateArea({}));
      setFirstTime(true);
    }
  }, [user]);

  useEffect(() => {
    if (user && role) {
      const tempRole = role.reduce((final, curr) => {
        if (curr.id === user.role_id) final = curr.name;
        return final;
      }, null);
      dispatch(setMyRole(tempRole));
    }
  }, [user, role]);

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          {user ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/dashboard" />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
