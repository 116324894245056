import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EJKNServiceSingleton } from "../../../services/api/ejkns.service";
import { OpenTicketTableSummary } from "./OpenTicketTableSummary";

export const OpenTicket: React.FC = () => {
  const [tickets, setTickets] = useState([]);

  const navigate = useNavigate();

  function navigateOpenTicket() {
    navigate("/ticket/open");
  }

  useEffect(() => {
    const getTicketsLists = async () => {
      const lists = await EJKNServiceSingleton.ticketsLists();
      if (lists.data.length > 0) setTickets(lists.data);
    };
    getTicketsLists();
  }, []);

  return (
    <div className="card col-m-12">
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        {/* begin::Title */}
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Open Tickets</span>
        </h3>
        {/* end::Title */}

        {/* begin::Toolbar */}
        <div className="card-toolbar">
          {/* begin::Menu */}
          <a
            href="#"
            onClick={navigateOpenTicket}
            className="btn btn-sm fw-bold btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_create_app"
          >
            See All Tickets
          </a>
          {/* <Dropdown1 /> */}
          {/* end::Menu */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body">
        <OpenTicketTableSummary tickets={tickets} />
      </div>
      {/* end::Body */}
    </div>
  );
};
