import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";
import { IPermitType, permitTypeInitialValues } from "../PermitTypeModel";

export const withPermitTypeFormLogic = (WrappedComponent: any) => {
  return () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState<IPermitType>(
      permitTypeInitialValues
    );

    useEffect(() => {
      if (id) {
        const getOrgDetails = async (id: number) => {
          try {
            setLoading(true);
            const data = await EJKNServiceSingleton.getPermitTypeById(id);
            setInitialValues(data.data);
          } catch (error) {
            ToastrServiceSingleton.error("Failed to fetch permit type details");
          } finally {
            setLoading(false);
          }
        };
        getOrgDetails(Number(id));
      } else {
        setInitialValues(permitTypeInitialValues);
      }
    }, [id]);

    return (
      <WrappedComponent
        initialValues={initialValues}
        mainLoading={loading}
        onFormSubmit={
          id
            ? (id: number, data: any) =>
                EJKNServiceSingleton.updatePermitType(id, data)
            : (data: any) => EJKNServiceSingleton.createPermitType(data)
        }
      />
    );
  };
};
