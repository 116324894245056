/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { MediaUpload } from "../../widgets/components/MediaUpload";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";

type Props = {
  show: boolean;
  onConfirm: (data: any) => {};
  ticketId: number;
  prePermitData?: any;
  onClose?: () => void;
};

interface IPermit {
  id?: number;
  name: string;
  description: string;
  permittype_id?: string;
  ticket_id?: number;
}

const modalsRoot = document.getElementById("root-modals") || document.body;

const permitSchema = Yup.object().shape({
  name: Yup.string().required("Name is required!!"),
  description: Yup.string().required("Description is required!!"),
  permittype_id: Yup.string().required("Please provide permit type!!"),
});

const PermitForm = ({
  show,
  onConfirm,
  ticketId,
  prePermitData = null,
  onClose,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [permitType, setPermitType] = useState([]);

  const { user } = useSelector(({ global }: any) => global);
  const [permitData, setPermitData] = useState<any>({
    id: null,
    name: "",
    permittype_id: null,
    description: "",
  });
  const formik = useFormik<IPermit>({
    initialValues: permitData,
    validationSchema: permitSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      await handleFormSubmit(values);
    },
  });

  async function handleFormSubmit(values: any) {
    let permit: IPermit = {
      ...values,
      ticket_id: ticketId,
    };
    let action = "create";
    if (permit.id) action = "update";
    try {
      setLoading(true);

      const data: any = await onConfirm(permit);
      setPermitData(data);

      ToastrServiceSingleton.success(`Successfull to ${action} permit!`);
    } catch (error) {
      ToastrServiceSingleton.error(`Failed to ${action} permit!`);
    } finally {
      setLoading(false);
    }
  }

  async function getPermitTypes() {
    const lists = await EJKNServiceSingleton.permitType();
    setPermitType(lists.data);
  }

  useEffect(() => {
    getPermitTypes();
  }, []);

  useEffect(() => {
    if (prePermitData) setPermitData(prePermitData);
  }, [prePermitData]);

  return (
    <form onSubmit={formik.handleSubmit} noValidate className="form">
      <div className="modal-header">
        <h2>{permitData.id ? "Update Permit" : "New Permit"}</h2>
      </div>

      <div className="modal-body py-lg-10 px-lg-10">
        <div className="row mb-6">
          <div className="col-sm-12">
            <label className="col-lg-4 col-form-label required fw-bold fs-6">
              Permit No
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              placeholder="Permit No"
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.name}</div>
              </div>
            )}
          </div>

          <div className="col-sm-12">
            <label className="col-lg-4 col-form-label required fw-bold fs-6">
              Description
            </label>
            <textarea
              className="form-control form-control-solid mb-8"
              rows={3}
              placeholder="Description"
              {...formik.getFieldProps("description")}
            ></textarea>
            {formik.touched.description && formik.errors.description && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.description}</div>
              </div>
            )}
          </div>
          <div className="row mb-6">
            <label className="col-lg-12 col-form-label required fw-bold fs-6">
              Types Of Permit
            </label>
            <div className="col-lg-12 fv-row">
              <select
                className="form-select form-select-solid form-select-lg"
                {...formik.getFieldProps("permittype_id")}
              >
                <option value="">Select a permit type...</option>
                {permitType.map((permit: any) => (
                  <option key={permit.id} value={permit.id}>
                    {permit.name}
                  </option>
                ))}

                {/* <option value="id">Hewlett Packard</option>
              <option value="msa">Dell</option> */}
              </select>
              {formik.touched.permittype_id && formik.errors.permittype_id && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.permittype_id}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <div className="modal-footer d-flex justify-content-between py-6 px-9">
        <button type="button" className="btn btn-default" onClick={onClose}>
          <span className="indicator-progress" style={{ display: "block" }}>
            Close
            {/* Please wait...{" "} */}
            {/* <span className="spinner-border spinner-border-sm align-middle ms-2"></span> */}
          </span>
        </button>
        <button type="submit" className="btn btn-primary">
          <span className="indicator-progress" style={{ display: "block" }}>
            {!loading && permitData.id ? "Update Permit" : "Store Permit"}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
            {/* Please wait...{" "} */}
            {/* <span className="spinner-border spinner-border-sm align-middle ms-2"></span> */}
          </span>
        </button>
      </div>
    </form>
  );
};

export { PermitForm };
