/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useSelector } from "react-redux";

const SidebarMenuMain = () => {
  const intl = useIntl();

  const { myRole } = useSelector(({ roles }: any) => roles);

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/art/art002.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />

      {myRole === "Administrator" && (
        <>
          <SidebarMenuItemWithSub
            to="/crafted/pages"
            title="Organizations"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/general/gen022.svg"
          >
            <SidebarMenuItem
              to="/organization/add"
              title="Add Organization"
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/organization/lists"
              title="List of Organizations"
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
        </>
      )}
      <SidebarMenuItemWithSub
        to="/ticket"
        title="Ticket"
        icon="/media/icons/duotune/general/gen025.svg"
        fontIcon="bi-person"
      >
        <SidebarMenuItem
          to="/ticket/open"
          title="Open Ticket"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/ticket/inprogress"
          title="In-Progress Tickets"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/ticket/completed"
          title="Completed Tickets"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      {myRole === "Administrator" && (
        <>
          <SidebarMenuItemWithSub
            to="/user"
            title="Users"
            icon="/media/icons/duotune/communication/com006.svg"
            fontIcon="bi-person"
          >
            <SidebarMenuItem to="/user/add" title="Add User" hasBullet={true} />
            <SidebarMenuItem
              to="/user/lists"
              title="List of Users"
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to="/role"
            title="Roles"
            icon="/media/icons/duotune/communication/com006.svg"
            fontIcon="bi-person"
          >
            <SidebarMenuItem to="/role/add" title="Add Role" hasBullet={true} />
            <SidebarMenuItem
              to="/role/lists"
              title="List of Roles"
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to="/district"
            title="Districts"
            icon="/media/icons/duotune/communication/com006.svg"
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/district/add"
              title="Add District"
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/district/lists"
              title="List of District"
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to="/zone"
            title="Zone"
            icon="/media/icons/duotune/communication/com006.svg"
            fontIcon="bi-person"
          >
            <SidebarMenuItem to="/zone/add" title="Add Zone" hasBullet={true} />
            <SidebarMenuItem
              to="/zone/lists"
              title="List of Zones"
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to="/states"
            title="State"
            icon="/media/icons/duotune/communication/com006.svg"
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/states/add"
              title="Add State"
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/states/lists"
              title="List of States"
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to="/status"
            title="Status"
            icon="/media/icons/duotune/communication/com006.svg"
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/status/add"
              title="Add Status"
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/status/lists"
              title="List of Status"
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to="/permittype"
            title="Permit Types"
            icon="/media/icons/duotune/communication/com006.svg"
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/permittype/add"
              title="Add Permit Type"
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/permittype/lists"
              title="List of Permit Types"
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
        </>
      )}

      {/* <SidebarMenuItemWithSub
        to="/accounts"
        title="Report"
        icon="/media/icons/duotune/general/gen005.svg"
        fontIcon="bi-person"
      >
        <SidebarMenuItem
          to="/account/overview"
          title="Summary Report"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub> */}
      <SidebarMenuItemWithSub
        to="/accounts"
        title="Map"
        icon="/media/icons/duotune/general/gen005.svg"
        fontIcon="bi-person"
      >
        <SidebarMenuItem to="/map/area" title="Area" hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div> */}
    </>
  );
};

export { SidebarMenuMain };
