/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { PageTitle } from "../../../_metronic/layout/core";
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  StatisticsWidget5,
  ChartsWidget1,
} from "../../../_metronic/partials/widgets";
import { InProgressTicket } from "../components/InProgressTicket";
import { OpenTicket } from "../components/OpenTicket";

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className="row g-5 g-xl-10">
      {/*  begin::Open Ticket*/}
      <OpenTicket />
      {/* end::Open Ticket */}

      {/*  begin::In-Progress Ticket*/}
      <InProgressTicket />
      {/* end::In-Progress Ticket */}

      {/* begin::Col */}
      <div className="col-md-12">
        <ChartsWidget1 className="card-xl-stretch mb-xl-8" />
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}
  </>
);

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
