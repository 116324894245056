import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EJKNServiceSingleton } from "../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../services/toastr/toastr.service";
import { OpenTicketTableLists } from "./components/OpenTicketTableLists";

export const OpenTicketPage = () => {
  const [loading, setLoading] = useState(false);

  const { myRole } = useSelector(({ roles }) => roles);
  const [tickets, setTickets] = useState([]);
  const navigate = useNavigate();

  function navigateToAddNewTicket() {
    navigate("/ticket/open/new");
  }

  function handleDeletionLocally(id: number) {
    setTickets((ticket) => ticket.filter((t: any) => t.id !== id));
  }

  useEffect(() => {
    const getTicketsLists = async () => {
      try {
        setLoading(true);
        const lists = await EJKNServiceSingleton.ticketsLists();
        if (lists.data.length > 0) setTickets(lists.data);
      } catch (error) {
        ToastrServiceSingleton.error("Fail to fetch open ticket lists");
      } finally {
        setLoading(false);
      }
    };
    getTicketsLists();
  }, []);
  return (
    <div className="card col-m-12">
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        {/* begin::Title */}
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Open Tickets</span>
        </h3>
        {/* end::Title */}

        {/* begin::Toolbar */}
        {myRole == "Administrator" && (
          <div className="card-toolbar">
            {/* begin::Menu */}
            <a
              href="#"
              onClick={() => navigateToAddNewTicket()}
              className="btn btn-sm fw-bold btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_create_app"
            >
              Add New Ticket
            </a>
            {/* <Dropdown1 /> */}
            {/* end::Menu */}
          </div>
        )}

        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body">
        {loading ? (
          <>
            <SkeletonTheme>
              {/* <div className="row col-md-12"> */}
              <Skeleton width={"100%"} height={30} />
              <Skeleton count={5} width={"100%"} height={30} />
              {/* </div> */}
            </SkeletonTheme>
          </>
        ) : (
          <OpenTicketTableLists
            tickets={tickets}
            handleDeletionLocally={handleDeletionLocally}
            administrator={myRole === "Administrator" || myRole === "Pilot"}
          />
        )}
      </div>
      {/* end::Body */}
    </div>
  );
};
