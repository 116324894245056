import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import AddStatePage from "./AddStatePage";
import StatesLists from "./StatesLists";

const zoneBreadCrumb: Array<PageLink> = [
  {
    title: "Edit State",
    path: "/states/edit",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "Add State",
    path: "/states/add",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "List of State",
    path: "/states/lists",
    isSeparator: true,
    isActive: false,
  },
];

const StatePage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path="edit/:id"
          element={
            <>
              <PageTitle breadcrumbs={zoneBreadCrumb}>Edit Zone</PageTitle>
              <AddStatePage />
            </>
          }
        />
        <Route
          path="add"
          element={
            <>
              <PageTitle breadcrumbs={zoneBreadCrumb}>Add State</PageTitle>
              <AddStatePage />
            </>
          }
        />
        <Route
          path="lists"
          element={
            <>
              <PageTitle breadcrumbs={zoneBreadCrumb}>List of State</PageTitle>
              <StatesLists />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default StatePage;
