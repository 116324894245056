export interface IStatus {
  name: string;
  description: string;
  order: number;
  id?: number;
}

export const statusInitialValues: IStatus = {
  name: "",
  description: "",
  order: 0,
};
