import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";
import { DropPointForm } from "./DropPointForm";

type Props = {
  show: boolean;
  handleClose: () => void;
  onConfirm: (data: any) => {};
  dropPoint: any;
  ticketId: number;
  role: string;
};
const modalsRoot = document.getElementById("root-modals") || document.body;

export const EditDropPointModal = ({
  show,
  handleClose,
  onConfirm,
  dropPoint = null,
  ticketId = 0,
  role = "",
}: Props) => {
  const [medias, setMedias] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  function closeModal() {
    setEdit(false);
    handleClose();
  }

  function imageFullscreen(id) {
    const image = document.querySelector(`#img${id}`);
    image.requestFullscreen();
  }
  useEffect(() => {
    if (dropPoint) {
      const getMediaByDropPoint = async () => {
        try {
          setLoading(true);
          const tempMedias = await EJKNServiceSingleton.mediaByDropPointId(
            dropPoint.id
          );
          setMedias(tempMedias.data);
        } catch (error) {
          ToastrServiceSingleton.error("Failed to fetch media");
        } finally {
          setLoading(false);
        }
      };
      getMediaByDropPoint();
    }
  }, [dropPoint]);
  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={closeModal}
      backdrop={true}
    >
      {!edit ? (
        <>
          <div className="modal-header">
            <h2>Update Drop Point</h2>
          </div>
          <div className="modal-body py-lg-10 px-lg-10">
            <div className="row">
              {loading ? (
                <SkeletonTheme>
                  <div className="col-md-12 p-2">
                    <Skeleton width={"100%"} height={"420px"} />
                  </div>
                  <div className="col-md-4 p-2">
                    <Skeleton width={"100%"} height={"100px"} />
                  </div>
                  <div className="col-md-4 p-2">
                    <Skeleton width={"100%"} height={"100px"} />
                  </div>
                  <div className="col-sm-12">
                    <Skeleton width={"100%"} height={"100px"} />
                  </div>
                  <div className="col-sm-12">
                    <Skeleton width={"100%"} height={"100px"} />
                  </div>
                </SkeletonTheme>
              ) : (
                <>
                  {/* {medias.length > 0 && (
                    <div className="col-md-12 p-2">
                      {medias[0].file_type === "mp4" ||
                      medias[0].file_type === "MP4" ? (
                        <video
                          controls
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "5px",
                          }}
                        >
                          <source
                            src={`${process.env.REACT_APP_STORAGE_URL}/${medias[0].file_name}.${medias[0].file_type}`}
                            type="video/mp4"
                          ></source>
                        </video>
                      ) : (
                        <img
                          id="img0"
                          src={`${process.env.REACT_APP_STORAGE_URL}/${medias[0].file_name}.${medias[0].file_type}`}
                          style={{
                            width: "100%",
                            height: "420px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => imageFullscreen(0)}
                        />
                      )}
                    </div>
                  )} */}
                  {medias.length > 0 &&
                    medias.map(
                      (media: any, i: number) =>
                        i >= 0 &&
                        (media.file_type === "mp4" ||
                        media.file_type === "MP4" ? (
                          <div className="col-md-4 col-sm-12 mt-2 p-2" key={i}>
                            <video
                              controls
                              style={{
                                height: "180px",
                                width: "100%",
                                borderRadius: "5px",
                              }}
                            >
                              <source
                                src={`${process.env.REACT_APP_STORAGE_URL}/${media.file_name}.${media.file_type}`}
                                type="video/mp4"
                              ></source>
                            </video>
                          </div>
                        ) : (
                          <div className="col-md-4 col-sm-12 mt-2 p-2" key={i}>
                            <img
                              id={`img${i}`}
                              src={`${process.env.REACT_APP_STORAGE_URL}/${media.file_name}.${media.file_type}`}
                              style={{
                                width: "100%",
                                height: "180px",
                                borderRadius: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => imageFullscreen(i)}
                            />
                          </div>
                        ))
                    )}

                  {dropPoint && (
                    <>
                      <div className="col-sm-12">
                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                          Description
                        </label>
                        <textarea
                          className="form-control form-control-solid mb-8"
                          rows={3}
                          placeholder="Description"
                          value={dropPoint.description}
                          disabled
                        ></textarea>
                      </div>

                      <div className="col-sm-12">
                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                          Address
                        </label>
                        <textarea
                          className="form-control form-control-solid mb-8"
                          rows={3}
                          placeholder="Address"
                          value={dropPoint.address}
                          disabled
                        ></textarea>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {/* </div> */}
          <div className="modal-footer d-flex justify-content-end py-6 px-9">
            {(role == "Administrator" || role == "Pilot") && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setEdit(true)}
              >
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Edit Drop Point
                  {/* Please wait...{" "} */}
                  {/* <span className="spinner-border spinner-border-sm align-middle ms-2"></span> */}
                </span>
              </button>
            )}
          </div>
        </>
      ) : (
        <DropPointForm
          show={show}
          onConfirm={onConfirm}
          ticketId={ticketId}
          dpData={dropPoint}
          sourceMedia={medias}
          onClose={handleClose}
        />
      )}
    </Modal>,
    modalsRoot
  );
};
