import * as dayjs from "dayjs";
import * as Exif from "exif-js";

class ImageUtilsService {
  private static instance: ImageUtilsService;

  private constructor() {}

  public static getInstance(): ImageUtilsService {
    if (!ImageUtilsService.instance) {
      ImageUtilsService.instance = new ImageUtilsService();
    }

    return ImageUtilsService.instance;
  }

  extractExifInformation(file: any) {
    const promise = new Promise((resolve, reject) => {
      Exif.getData(file, function () {
        const allMetaData = Exif.getAllTags(this);
        if (allMetaData) resolve(allMetaData);
        else reject({ error: "File don't have metadata!!" });
      });
    });
    return promise;
  }
}

export const ImageUtilsServiceSingleton = ImageUtilsService.getInstance();
