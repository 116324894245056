import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import AddOrganizationPage from "./AddOrganizationPage";
import OrganizationLists from "./OrganizationLists";

const organizationBreadCrumb: Array<PageLink> = [
  {
    title: "Add Organization",
    path: "/organization/add",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "List of Organization",
    path: "/organization/lists",
    isSeparator: true,
    isActive: false,
  },
];

const OrganizationPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path="edit/:id"
          element={
            <>
              <PageTitle breadcrumbs={organizationBreadCrumb}>
                Edit Organization
              </PageTitle>
              <AddOrganizationPage />
            </>
          }
        />
        <Route
          path="add"
          element={
            <>
              <PageTitle breadcrumbs={organizationBreadCrumb}>
                Add Organization
              </PageTitle>
              <AddOrganizationPage />
            </>
          }
        />
        <Route
          path="lists"
          element={
            <>
              <PageTitle breadcrumbs={organizationBreadCrumb}>
                List of Organization
              </PageTitle>
              <OrganizationLists />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default OrganizationPage;
