import React from "react";
import { useNavigate } from "react-router-dom";
import { AreaUtilsServiceSingleton } from "../../../services/api/area-utils.service";
import { DateUtilsServiceSingleton } from "../../../services/utilities/data/date-utils.service";
import { KTSVG } from "../../../_metronic/helpers";

export const InProgressTicketTableSummary = ({ tickets = [] }) => {
  const navigate = useNavigate();

  function navigateToTicketDetail(id: number) {
    navigate(`/ticket/detail/${id}`);
  }

  function convertDate(date: string) {
    return DateUtilsServiceSingleton.convertDateDefault(date);
  }

  function getZoneById(id: number) {
    return AreaUtilsServiceSingleton.convertZoneById(id);
  }

  function getDistrictById(id: number) {
    return AreaUtilsServiceSingleton.convertDistrictById(id);
  }

  return (
    <div className="table-responsive">
      {/* begin::Table */}
      <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
        {/* begin::Table head */}
        <thead>
          <tr className="fw-bold text-muted">
            <th className="text-dark">Ticket Number</th>
            <th className="text-dark">Zone</th>
            <th className="text-dark">District</th>
            {/* <th className="text-dark">Postcode</th> */}
            <th className="text-dark text-end">Issue Date</th>
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {tickets.map((ticket: any) => (
            <tr key={ticket.id}>
              <td>
                <a
                  href="#"
                  onClick={() => navigateToTicketDetail(ticket.id)}
                  className="fw-bold text-hover-primary fs-6"
                >
                  {ticket.number}
                </a>
              </td>
              <td>
                <a
                  href="#"
                  className="text-muted fw-bold text-hover-primary d-block mb-1 fs-6"
                >
                  {getZoneById(ticket.zone_id)}
                </a>
              </td>

              <td>
                <a
                  href="#"
                  className="text-muted fw-bold text-hover-primary d-block mb-1 fs-6"
                >
                  {getDistrictById(ticket.district_id)}
                </a>
              </td>

              <td>
                <a
                  href="#"
                  className="text-muted fw-bold text-hover-primary d-block mb-1 fs-6 text-end"
                >
                  {convertDate(ticket.created_at)}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
        {/* end::Table body */}
      </table>
      {/* end::Table */}
    </div>
  );
};
