import React from "react";
import ZoneForm from "./components/ZoneForm";
import { withZoneFormLogic } from "./hoc/ZoneFormLogic";

const ZoneFormWithLogic = withZoneFormLogic(ZoneForm);
const AddZonePage: React.FC = () => {
  return (
    <>
      <ZoneFormWithLogic />
    </>
  );
};

export default AddZonePage;
