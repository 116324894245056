import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Value } from "sass";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { KTSVG } from "../../../../_metronic/helpers";
import { ConfirmationModal } from "../../widgets/components/ConfirmationModal";

export default function OrganizationTable({
  organizationsList = [],
  handleDeletionLocally = (id: number) => {},
}) {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState<{
    id: number;
    status: string;
  } | null>(null);

  function onTriggerDelete(id: number) {
    setShowModal(true);
    setDeleteId({
      id: id,
      status: "draft",
    });
  }

  function confirmDelete() {
    setDeleteId((value: any) => ({ id: value.id, status: "delete" }));
  }

  async function deleteOrganization(id: number) {
    const deletedData: any = await EJKNServiceSingleton.deleteOrganizationLists(
      id
    );
    handleDeletionLocally(deletedData.data.id);
    setShowModal(false);
    setDeleteId(null);
  }

  function navigateToEditOrganization(id: number) {
    navigate(`/organization/edit/${id}`);
  }

  useEffect(() => {
    if (deleteId?.status === "delete") {
      deleteOrganization(deleteId.id);
    }
  }, [deleteId]);

  return (
    <>
      <div className="table-responsive">
        {/* begin::Table */}
        <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
          {/* begin::Table head */}
          <thead>
            <tr className="fw-bold text-dark">
              <th>No</th>
              <th>Name</th>
              <th>Description</th>
              <th className="text-end">Actions</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {organizationsList.map((org: any, i: number) => (
              <tr key={org.id}>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary fs-6"
                  >
                    {i + 1}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-primary fw-bold text-hover-primary d-block mb-1 fs-6"
                    onClick={() => navigateToEditOrganization(org.id)}
                  >
                    {org.name}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-muted fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    {org.description}
                  </a>
                </td>

                <td className="text-end">
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                    onClick={() => navigateToEditOrganization(org.id)}
                  >
                    <KTSVG
                      path="/media/icons/duotune/art/art005.svg"
                      className="svg-icon-3"
                    />
                  </a>
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                    onClick={() => onTriggerDelete(org.id)}
                  >
                    <KTSVG
                      path="/media/icons/duotune/general/gen027.svg"
                      className="svg-icon-3"
                    />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      <ConfirmationModal
        show={showModal}
        handleClose={() => setShowModal(false)}
      >
        <div className="modal-header">
          <h2>Delete Organization</h2>
        </div>

        <div className="modal-body py-lg-10 px-lg-10">
          <h3>Are you sure want to proceed?</h3>
        </div>
        <div className="modal-footer d-flex justify-content-between py-6 px-9">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => setShowModal(false)}
          >
            <span className="indicator-progress" style={{ display: "block" }}>
              Close
              {/* Please wait...{" "} */}
              {/* <span className="spinner-border spinner-border-sm align-middle ms-2"></span> */}
            </span>
          </button>
          <button
            type="submit"
            className="btn btn-danger"
            onClick={confirmDelete}
          >
            <span className="indicator-progress" style={{ display: "block" }}>
              Confirm
            </span>
          </button>
        </div>
      </ConfirmationModal>
    </>
  );
}
