export interface ITicket {
  zone_id: string;
  address: string;
  description: string;
  district_id: string;
  latitude: string;
  longitude: string;
  title: string;
  number?: string;
  status?: string;
  id?: number;
  //   image_signature?: string | null;
  status_id?: number | null;
}

export const ticketInitialValues: ITicket = {
  zone_id: "",
  address: "",
  description: "",
  district_id: "",
  latitude: "",
  longitude: "",
  title: "",
  // image_signature: null,
  status_id: null,
};
