import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EJKNServiceSingleton } from "../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../services/toastr/toastr.service";
import { DateUtilsServiceSingleton } from "../../../services/utilities/data/date-utils.service";
import { LoaderComponent } from "../../pages/components/LoaderComponent";
import { DropPointModal } from "./components/DropPointModal";
import { DropPointTable } from "./components/DropPointTable";
import { EditDropPointModal } from "./components/EditDropPointModal";
import { EditTicketForm } from "./components/EditTicketForm";
import { PdfModal } from "./components/PdfModal";
import { PermitModal } from "./components/PermitModal";
import { PermitTable } from "./components/PermitTable";
import { SignatureModal } from "./components/SignatureModal";
import { SignatureTable } from "./components/SignatureTable";
import { ITicket } from "./TicketModel";

export const CreateNewTicketPage = () => {
  const { id } = useParams();
  const { myRole } = useSelector(({ roles }: any) => roles);
  const { isLoading } = useSelector(({ global }: any) => global);
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [formattedStatus, setFormattedStatus] = useState([]);

  const [logs, setLogs] = useState({});
  const [dropPoints, setDropPoints] = useState<any[]>([]);
  const [signatures, setSignatures] = useState<any[]>([]);
  const [permits, setPermits] = useState<any[]>([]);
  const [currentPermit, setCurrentPermit] = useState<any>(null);
  const [showDropPointModal, setShowDropPointModal] = useState(false);
  const [showPDF, setShowPdf] = useState(false);
  const [showPermitModal, setShowPermitModal] = useState(false);
  const [showEditDropPointModal, setShowEditDropPointModal] = useState(false);
  const [currentDropPoint, setCurrentDropPoint] = useState<any>(null);
  const [showSignModal, setShowSignModal] = useState(false);
  const [ticket, setTicket] = useState<ITicket>({
    zone_id: "",
    address: "",
    description: "",
    district_id: "",
    latitude: "",
    longitude: "",
    // image_signature: null,
    title: "",
    number: "",
    status_id: null,
    id: 0,
  });

  async function handleCreatePermit(data: any) {
    const permit: any = data.id
      ? await EJKNServiceSingleton.updatePermit(data.id, data)
      : await EJKNServiceSingleton.createPermit(data);

    if (data.id) {
      const tempPermits = permits.map((p) => {
        if (p.id === data.id) {
          return permit.data;
        } else return p;
      });
      setPermits(tempPermits);
    } else {
      let perms = [...permits, permit.data];
      setPermits(perms);
    }

    return permit.data;
  }

  async function handleCreateDropPoint(data: any) {
    const dropPoint: any = data.id
      ? await EJKNServiceSingleton.updateDropPoint(data.id, data)
      : await EJKNServiceSingleton.createDropPoint(data);

    if (data.id) {
      const tempDropPoints = dropPoints.map((dp) => {
        if (dp.id === data.id) {
          return dropPoint.data;
        } else return dp;
      });
      setDropPoints(tempDropPoints);
    } else {
      let points = [...dropPoints, dropPoint.data];
      setDropPoints(points);
    }

    return dropPoint.data;
  }

  async function getTicketLogs() {
    try {
      setStatusLoading(true);
      const result = await EJKNServiceSingleton.getLogByTicketId(Number(id));
      const tempData = result.data.reduce((final, curr) => {
        if (!final[curr.status_id]) {
          let tempData = {};
          tempData[curr.status_id] = curr.updated_at;
          final = { ...final, ...tempData };
        }

        return final;
      }, {});

      setLogs(tempData);
    } catch (error) {
      console.log(error);
    } finally {
      setStatusLoading(false);
    }
  }

  async function handleSignature(data: any) {
    try {
      const signatureData = await EJKNServiceSingleton.createSignature({
        ...data,
        ticket_id: id,
      });

      setSignatures((val) => [...val, signatureData.data]);
      ToastrServiceSingleton.success("Form has been signed!");
    } catch (error) {
      ToastrServiceSingleton.error("Fail to sign form");
    }
  }

  function updateTicketStatus(status_id: number) {
    setTicket((ticket) => ({ ...ticket, status_id }));
  }

  function editCurrentDropPoint(droppoint: any) {
    setCurrentDropPoint(droppoint);
    setShowEditDropPointModal(true);
  }

  function editCurrentPermit(permit: any) {
    setCurrentPermit(permit);
    setShowPermitModal(true);
  }

  function handleDropPointDeletionLocal(id: number) {
    setDropPoints((dropPoint: any) =>
      dropPoint.filter((d: any) => d.id !== id)
    );
  }

  function handlePermitDeletionLocal(id: number) {
    setPermits((permit: any) => permit.filter((p: any) => p.id !== id));
  }

  function addPermit() {
    setCurrentPermit(null);
    setShowPermitModal(true);
  }

  async function generateReport() {
    await EJKNServiceSingleton.generateReport();
  }

  function getStatusUpdatedDate(id) {
    if (logs && Object.keys(logs).length > 0) {
      if (logs[id]) {
        return DateUtilsServiceSingleton.convertDateDefault(
          logs[id],
          "DD-MM-YYYY HH:MM:ss a"
        );
      }
    }
    return "N/A";
  }

  useEffect(() => {
    const getStatuses = async () => {
      try {
        setLoading(true);
        const tempStatus = await EJKNServiceSingleton.statuses();
        setStatuses(tempStatus.data);
      } catch (error) {
        ToastrServiceSingleton.error("Error fetch ticket details!");
      }
    };
    if (id) getStatuses();
  }, [id]);

  useEffect(() => {
    const getTicketById = async () => {
      try {
        setLoading(true);
        const tempTicket = await EJKNServiceSingleton.getTicketById(Number(id));
        if (tempTicket && tempTicket.data) {
          setTicket(tempTicket.data);
        }
      } catch (error) {
        ToastrServiceSingleton.error("Error fetch ticket details!");
      } finally {
        setLoading(false);
      }
    };

    const getTicketDropPoints = async () => {
      const tempDropPoints = await EJKNServiceSingleton.getDropPointsByTicketId(
        Number(id)
      );
      setDropPoints(tempDropPoints.data);
    };

    const getTicketSignature = async () => {
      const tempSignatures = await EJKNServiceSingleton.signatureByTicketId(
        Number(id)
      );
      setSignatures(tempSignatures.data);
    };

    const getTicketPermit = async () => {
      const tempPermit = await EJKNServiceSingleton.getPermitByTicketId(
        Number(id)
      );
      setPermits(tempPermit.data);
    };

    const formattedStatuses = () => {
      const temp = statuses.map((status) => ({
        value: status.id,
        name: status.name,
      }));
      setFormattedStatus(temp);
    };
    if (statuses.length > 0) {
      // if (id) {
      getTicketById();
      getTicketDropPoints();
      getTicketSignature();
      getTicketPermit();
      formattedStatuses();
      // getTicketLogs();
      // }
    }
  }, [statuses]);

  useEffect(() => {
    if (ticket) {
      getTicketLogs();
    }
  }, [ticket]);

  useEffect(() => {
    console.log(isLoading, "loading");
  }, [isLoading]);

  return (
    <div className="row">
      {loadingGlobal && <LoaderComponent />}
      <div className="col-sm-12 col-md-4 d-flex mt-3">
        <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid">
          {/* begin::Aside*/}
          <div className="card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 me-9">
            {/* begin::Wrapper*/}
            <div className="card-body px-6 px-lg-10 px-xxl-15 py-20">
              {/* begin::Nav*/}
              {statusLoading ? (
                <Skeleton count={5} width="100%" height={30} />
              ) : (
                <div className="stepper-nav">
                  {/* begin::Step 1*/}
                  {statuses.map((status, i) => (
                    <div
                      key={status.id}
                      className={`stepper-item ${
                        ticket && ticket.status_id === status.id && "current"
                      }`}
                      data-kt-stepper-element="nav"
                    >
                      {/* begin::Wrapper*/}

                      <div className="stepper-wrapper">
                        {/* begin::Icon*/}
                        <div className="stepper-icon w-40px h-40px">
                          <i className="stepper-check fas fa-check"></i>
                          <span className="stepper-number">{i + 1}</span>
                        </div>
                        {/* end::Icon*/}

                        {/* begin::Label*/}
                        <div className="stepper-label">
                          <h3 className="stepper-title">{status.name}</h3>

                          <div className="stepper-desc fw-semibold">
                            {status.description}
                          </div>
                          <div className="text-small">
                            <span className="text-dark fw-semibold">
                              Updated At:{" "}
                            </span>
                            <span className="text-primary">
                              {getStatusUpdatedDate(status.id)}
                            </span>
                          </div>
                        </div>
                        {/* end::Label*/}
                      </div>
                      {/* end::Wrapper*/}

                      {/* begin::Line*/}
                      <div className="stepper-line h-40px"></div>
                      {/* end::Line*/}
                    </div>
                  ))}

                  {/* end::Step 1*/}
                </div>
              )}
              {/* end::Nav*/}
            </div>
            {/* end::Wrapper*/}
          </div>
          {/* begin::Aside*/}
        </div>
      </div>
      <div className="col-sm-12 col-md-8 d-flex mt-3">
        <div className="card d-flex flex-column flex-xl-row flex-row-fluid">
          <div className="card-body">
            {loading ? (
              <Skeleton count={7} width="100%" height={30} />
            ) : (
              <EditTicketForm
                ticket={ticket}
                onOpenSign={() => setShowSignModal(true)}
                updateTicketStatus={updateTicketStatus}
                role={myRole}
                statuses={formattedStatus}
              />
            )}
          </div>
        </div>
      </div>
      <div className="col-sm-12 mt-3">
        <div className="card">
          <div className="card-header border-0 pt-5">
            {/* begin::Title */}
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">Permits</span>
            </h3>
            {/* end::Title */}

            {/* begin::Toolbar */}
            <div className="card-toolbar">
              {(myRole == "Administrator" || myRole == "Pilot") && (
                <a
                  href="#"
                  onClick={addPermit}
                  className="btn btn-sm fw-bold btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_create_app"
                >
                  Add Permit
                </a>
              )}
              {/* begin::Menu */}

              {/* <Dropdown1 /> */}
              {/* end::Menu */}
            </div>
            {/* end::Toolbar */}
          </div>
          <div className="card-body">
            <PermitTable
              permits={permits}
              onEdit={editCurrentPermit}
              handleDeletionLocally={handlePermitDeletionLocal}
              role={myRole}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-12 mt-3">
        <div className="card">
          <div className="card-header border-0 pt-5">
            {/* begin::Title */}
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">Drop Points</span>
            </h3>
            {/* end::Title */}

            {/* begin::Toolbar */}
            <div className="card-toolbar">
              {(myRole == "Administrator" || myRole == "Pilot") && (
                <a
                  href="#"
                  onClick={() => setShowDropPointModal(true)}
                  className="btn btn-sm fw-bold btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_create_app"
                >
                  Add Drop Point
                </a>
              )}
              {/* begin::Menu */}

              {/* <Dropdown1 /> */}
              {/* end::Menu */}
            </div>
            {/* end::Toolbar */}
          </div>
          <div className="card-body">
            <DropPointTable
              dropPoints={dropPoints}
              onEdit={editCurrentDropPoint}
              handleDeletionLocally={handleDropPointDeletionLocal}
              role={myRole}
            />
            <PermitModal
              show={showPermitModal}
              handleClose={() => {
                setShowPermitModal(false);
              }}
              onConfirm={handleCreatePermit}
              permit={currentPermit}
              ticketId={Number(id)}
            />
            <PdfModal
              show={showPDF}
              ticket={ticket}
              statuses={statuses}
              dropPoints={dropPoints}
              handleClose={() => {
                setShowPdf(false);
              }}
              setLoadingGlobal={setLoadingGlobal}
            />
            <DropPointModal
              show={showDropPointModal}
              handleClose={() => {
                setShowDropPointModal(false);
              }}
              onConfirm={handleCreateDropPoint}
              ticketId={Number(id)}
            />
            <EditDropPointModal
              dropPoint={currentDropPoint}
              show={showEditDropPointModal}
              handleClose={() => {
                setShowEditDropPointModal(false);
                setCurrentDropPoint(null);
              }}
              onConfirm={handleCreateDropPoint}
              ticketId={Number(id)}
              role={myRole}
            />
            <SignatureModal
              show={showSignModal}
              handleClose={() => {
                setShowSignModal(false);
              }}
              signature={null}
              submitSignature={handleSignature}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-12 mt-3 mb-2">
        <div className="card">
          <div className="card-header border-0 pt-5">
            <span className="card-label fw-bold fs-3 mb-1">
              Signatures by PBT
            </span>
            <div className="card-toolbar">
              {(myRole == "Administrator" || myRole == "Pilot") && (
                <div className="col-md-12 mt-3 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => setShowSignModal(true)}
                  >
                    Signature
                  </button>
                </div>
              )}
              {/* begin::Menu */}

              {/* <Dropdown1 /> */}
              {/* end::Menu */}
            </div>
          </div>
          <div className="card-body">
            <SignatureTable signatures={signatures} />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header border-0 pt-5">
          <span className="card-label fw-bold fs-3 mb-1">Reporting</span>
        </div>
        <div className="card-body">
          <button
            className="btn btn-primary"
            style={{ float: "right", clear: "both" }}
            onClick={() => setShowPdf(true)}
          >
            Generate Report
          </button>
        </div>
      </div>

      {/* {(myRole == "Administrator" || myRole == "Pilot") && (
        <div className="col-md-12 mt-3 d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => setShowSignModal(true)}
          >
            Signature
          </button>
        </div>
      )} */}
    </div>
  );
};
