import { createSlice } from "@reduxjs/toolkit";

interface IState {
  name: string;
  abbreviation: string;
  id?: number;
}

interface IStateState {
  states: IState[];
  isLoading: boolean;
  error: string | null;
  success: boolean | null;
}

const initialState: IStateState = {
    states: [],
  isLoading: false,
  error: null,
  success: false,
};

const stateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    replaceState: (state, action) => {
      state.isLoading = false;
      state.states = action.payload;
      state.error = null;
      state.success = true;
    },
    addState: (state, action) => {
      state.isLoading = false;
      state.states = [...state.states, action.payload];
      state.error = null;
      state.success = true;
    },
    removeState: (state, action) => {
      state.isLoading = false;
      state.states = state.states.filter(
        (org: any) => org.id !== action.payload
      );
      state.error = null;
      state.success = true;
    },
    loading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: {},
});

export const { loading, replaceState, removeState } =
stateSlice.actions;

export default stateSlice.reducer;
