import axios from "axios";
import { store } from "../../store";

const prefix = process.env.REACT_APP_PREFIX_API;
const authPrefix = `/${prefix}/auth`;
const mePrefix = `/${prefix}/me`;
const orgPrefix = `/${prefix}/organizations`;
const rolesPrefix = `/${prefix}/roles`;
const ticketPrefix = `/${prefix}/tickets`;
const zonePrefix = `/${prefix}/zones`;
const districtPrefix = `/${prefix}/districts`;
const userPrefix = `/${prefix}/users`;
const statePrefix = `/${prefix}/states`;
const dropPointPrefix = `/${prefix}/drop-points`;
const logsPrefix = `/${prefix}/logs`;
const mediaPrefix = `/${prefix}/medias`;
const signaturePrefix = `/${prefix}/signatures`;
const permitPrefix = `/${prefix}/permit`;
const permitTypePrefix = `/${prefix}/permittype`;
const statusesPrefix = `/${prefix}/statuses`;
const reportPrefix = `/${prefix}/reports`;

// const districtPrefix = `/${prefix}/districts`;

class EJKNService {
  private static instance: EJKNService;
  private _http: any;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {}

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): EJKNService {
    if (!EJKNService.instance) {
      EJKNService.instance = new EJKNService();
      EJKNService.instance._settingAxios();
    }

    return EJKNService.instance;
  }

  // authentication
  async authenticate(data: any) {
    return await this._post(`${authPrefix}/login`, data);
  }

  async authenticateRegister(data: any) {
    return await this._post(`${userPrefix}`, data);
  }

  async me() {
    return await this._get(`${authPrefix}/me`);
  }

  // end authentication

  // zones
  async zoneLists() {
    return await this._get(`${zonePrefix}`);
  }

  async zoneById(id: number) {
    return await this._get(`${zonePrefix}/${id}`);
  }

  async createZone(data: any) {
    return await this._post(`${zonePrefix}`, data);
  }

  async updateZone(id: number, data: any) {
    return await this._put(`${zonePrefix}/${id}`, data);
  }

  async deleteZone(id: number) {
    return await this._delete(`${zonePrefix}/${id}`);
  }
  // end zones

  // districts
  async districtsLists() {
    return await this._get(`${districtPrefix}`);
  }

  async districtsById(id: number) {
    return await this._get(`${districtPrefix}/${id}`);
  }

  async createDistrict(data: any) {
    return await this._post(`${districtPrefix}`, data);
  }

  async updateDistrict(id: number, data: any) {
    return await this._put(`${districtPrefix}/${id}`, data);
  }

  async deleteDisctrict(id: number) {
    return await this._delete(`${districtPrefix}/${id}`);
  }
  // end districts

  // roles
  async roles() {
    return await this._get(`${rolesPrefix}`);
  }

  async roleById(id: number) {
    return await this._get(`${rolesPrefix}/${id}`);
  }

  async createRole(data: any) {
    return await this._post(`${rolesPrefix}`, data);
  }

  async updateRole(id: number, data: any) {
    return await this._put(`${rolesPrefix}/${id}`, data);
  }

  async deleteRole(id: number) {
    return await this._delete(`${rolesPrefix}/${id}`);
  }
  // end roles

  // organization
  async organizationLists() {
    return await this._get(`${orgPrefix}`);
  }

  async addOrganizationLists(data: any) {
    return await this._post(`${orgPrefix}`, data);
  }

  async deleteOrganizationLists(id: number) {
    return await this._delete(`${orgPrefix}/${id}`);
  }

  async getOrganizationById(id: number) {
    return this._get(`${orgPrefix}/${id}`);
  }

  async updateOrganization(id: number, data: any) {
    return await this._put(`${orgPrefix}/${id}`, data);
  }

  // end organization

  // ticket
  async ticketsLists() {
    return await this._get(`${ticketPrefix}`);
  }

  async allticketsLists() {
    return await this._get(`${ticketPrefix}/all`);
  }

  async inProgressTicketsLists() {
    return await this._get(`${ticketPrefix}/in-progress`);
  }

  async completedTicketsLists() {
    return await this._get(`${ticketPrefix}/completed`);
  }

  async createTicket(data: any) {
    return await this._post(`${ticketPrefix}`, data);
  }

  async getTicketById(id: number) {
    return await this._get(`${ticketPrefix}/${id}`);
  }

  async deleteTicketById(id: number) {
    return await this._delete(`${ticketPrefix}/${id}`);
  }

  async updateTicket(id: number, data: any) {
    return await this._put(`${ticketPrefix}/${id}`, data);
  }

  async summaryTicket() {
    return await this._get(`${ticketPrefix}/summary`);
  }
  // end ticket

  // user
  async users() {
    return await this._get(`${userPrefix}`);
  }

  async getUserById(id: number) {
    return await this._get(`${userPrefix}/${id}`);
  }

  async deleteUser(id: number) {
    return await this._delete(`${userPrefix}/${id}`);
  }

  async updateUser(id: number, data: any) {
    return await this._put(`${userPrefix}/${id}`, data);
  }
  // end user

  // states
  async states() {
    return await this._get(`${statePrefix}`);
  }

  async createState(data: any) {
    return await this._post(`${statePrefix}`, data);
  }

  async getStateById(id: number) {
    return await this._get(`${statePrefix}/${id}`);
  }

  async deleteState(id: number) {
    return await this._delete(`${statePrefix}/${id}`);
  }

  async updateState(id: number, data: any) {
    return await this._put(`${statePrefix}/${id}`, data);
  }
  // end states

  // drop point

  async dropPoints() {
    return await this._get(`${dropPointPrefix}`);
  }

  async createDropPoint(data: any) {
    return await this._post(`${dropPointPrefix}`, data);
  }

  async updateDropPoint(id: number, data: any) {
    return await this._put(`${dropPointPrefix}/${id}`, data);
  }

  async getDropPointsByTicketId(id: number) {
    return await this._get(`${dropPointPrefix}/tickets/${id}`);
  }

  async deleteDropPoints(id: number) {
    return await this._delete(`${dropPointPrefix}/${id}`);
  }

  async dropPointsMap() {
    return await this._get(`${dropPointPrefix}/map`);
  }

  async dropPointsMapByTicket(id: number) {
    return await this._get(`${dropPointPrefix}/map/${id}`);
  }
  // end drop point

  // logs
  async createLog(data: any) {
    return await this._post(`${logsPrefix}`, data);
  }

  async getLogByTicketId(id: number) {
    return await this._get(`${logsPrefix}/tickets/${id}`);
  }
  // end logs

  // media
  async storeMedia(data: any) {
    return await this._post(`${mediaPrefix}`, data);
  }

  async mediaByDropPointId(id: number) {
    return await this._get(`${mediaPrefix}/drop-points/${id}`);
  }

  async mediaByDropPoints(data: any) {
    return await this._post(`${mediaPrefix}/drop-points-multiple`, data);
  }

  async deleteMedia(id: number) {
    return await this._delete(`${mediaPrefix}/${id}`);
  }
  // end media

  // signature
  async createSignature(data: any) {
    return await this._post(`${signaturePrefix}`, data);
  }

  async signatureByTicketId(id: number) {
    return await this._get(`${signaturePrefix}/tickets/${id}`);
  }

  // end signature

  // permit

  async permit() {
    return await this._get(`${permitPrefix}`);
  }

  async createPermit(data: any) {
    return await this._post(`${permitPrefix}`, data);
  }

  async updatePermit(id: number, data: any) {
    return await this._put(`${permitPrefix}/${id}`, data);
  }

  async getPermitByTicketId(id: number) {
    return await this._get(`${permitPrefix}/tickets/${id}`);
  }

  async deletePermit(id: number) {
    return await this._delete(`${permitPrefix}/${id}`);
  }
  // end permit

  // permit type

  async permitType() {
    return await this._get(`${permitTypePrefix}`);
  }

  async getPermitTypeById(id: number) {
    return await this._get(`${permitTypePrefix}/${id}`);
  }

  async createPermitType(data: any) {
    return await this._post(`${permitTypePrefix}`, data);
  }

  async updatePermitType(id: number, data: any) {
    return await this._put(`${permitTypePrefix}/${id}`, data);
  }

  async deletePermitType(id: number) {
    return await this._delete(`${permitTypePrefix}/${id}`);
  }
  // end permit type

  // status
  async statuses() {
    return this._get(`${statusesPrefix}`);
  }

  async deleteStatus(id: number) {
    return await this._delete(`${statusesPrefix}/${id}`);
  }

  async statusById(id: number) {
    return await this._get(`${statusesPrefix}/${id}`);
  }

  async createStatus(data: any) {
    return await this._post(`${statusesPrefix}`, data);
  }

  async updateStatus(id: number, data: any) {
    return await this._put(`${statusesPrefix}/${id}`, data);
  }
  // end status

  // start report
  async generateReport() {
    return await this._post(`${reportPrefix}`, {});
  }
  // end report

  private _settingAxios() {
    // ...
    this._http = axios.create({
      baseURL: process.env.REACT_APP_EJKNS_API,
    });

    this._interceptRequest();
  }

  private async _get(url: string) {
    let data = await this._http.get(url);
    return data.data;
  }

  private _post(url: string, data: any) {
    return this._http.post(url, data).then((res: any) => res.data);
  }

  private _put(url: string, data: any) {
    return this._http.put(url, data).then((res: any) => res.data);
  }

  private _delete(url: string) {
    return this._http.delete(url).then((res: any) => res.data);
  }

  private _interceptRequest() {
    this._http.interceptors.request.use(
      (config: any) => {
        if (config.url.indexOf("login") < 0) {
          const tempStore = store.getState();
          const { global } = tempStore;
          const { token } = global;
          if (token) {
            const extractToken = token.access_token;
            if (extractToken) {
              config.headers["Authorization"] = `Bearer ${extractToken}`; // for Spring Boot back-end
              config.headers["x-access-token"] = extractToken; // for Node.js Express back-end
            }
          }
        }

        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );
  }
}

export const EJKNServiceSingleton = EJKNService.getInstance();
