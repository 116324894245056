export interface IRole {
  name: string;
  description: string;
  id?: number;
}

export const defaultRole: IRole = {
  name: "Admin",
  description: "Managing App",
};

export const roleInitialValues: IRole = {
  name: "",
  description: "",
};
