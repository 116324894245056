import React, { useEffect, useRef, useState } from "react";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";
import { CoordinateUtilsServiceSingleton } from "../../../../services/utilities/gis/coordinate-utils.service";
import { ImageUtilsServiceSingleton } from "../../../../services/utilities/image/image-utils.service";

export const MediaUpload = ({
  media = null,
  mediaIndex = 0,
  fileUpload = (data: any, metadata: any, index: number) => {},
  deleteMedia = (id: number) => {},
}) => {
  const [tempUrl, setTempUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [isItImage, setIsItImage] = useState<boolean>(false);
  const hiddenFileInput = useRef<any>(null);

  const handleClick = (event: any) => {
    if (media.file_name === "")
      if (hiddenFileInput && hiddenFileInput.current && hiddenFileInput.current)
        hiddenFileInput.current.click();
  };

  const handleChange = async (event: any) => {
    try {
      setLoading(true);
      const fileUploaded = event.target.files[0];
      const supportedFormat = ["video/mp4", "image/jpeg"];
      if (supportedFormat.indexOf(event.target.files[0].type) >= 0) {
        const url = URL.createObjectURL(event.target.files[0]);
        setIsItImage(
          event.target.files[0].type.indexOf("mp4") >= 0 ? false : true
        );
        setTempUrl(url);

        const metadata =
          await ImageUtilsServiceSingleton.extractExifInformation(
            event.target.files[0]
          );
        const coordinate =
          CoordinateUtilsServiceSingleton.extractCoordinateFromMetadata(
            metadata
          );
        await fileUpload(fileUploaded, coordinate, mediaIndex);
      } else {
        ToastrServiceSingleton.error("File format not supported!");
      }
    } catch (error) {
      ToastrServiceSingleton.error("Fail to upload media!");
    } finally {
      setLoading(false);
    }
  };

  async function handleDeleteMedia(media: any) {
    try {
      setLoading(true);
      await deleteMedia(media);
    } catch (error) {
      ToastrServiceSingleton.error("Failed to delete media");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (media && media.id && media.file_name) {
      setIsItImage(
        media.file_type.toLowerCase().indexOf("mp4") >= 0 ? false : true
      );
      setTempUrl(
        `${process.env.REACT_APP_STORAGE_URL}/${media.file_name}.${media.file_type}`
      );
    }
  }, [media]);
  return (
    <>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      {loading ? (
        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 upload-image">
          <div className="fs-6 fw-bolder text-gray-700 text-center p-10">
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </div>
        </div>
      ) : tempUrl ? (
        <div
          className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 upload-image no-pad"
          onClick={handleClick}
          style={{ position: "relative" }}
        >
          <span
            style={{
              position: "absolute",
              right: "-10px",
              top: "-20px",
              backgroundColor: "red",
              padding: "5px 8px",
              color: "#fff",
              borderRadius: "5px",
            }}
            onClick={() => handleDeleteMedia(media)}
          >
            <i className="bi bi-x-lg" style={{ color: "#fff" }} />
          </span>
          {isItImage ? (
            <img
              src={tempUrl}
              style={{ height: "100%", width: "100%", borderRadius: "5px" }}
            />
          ) : (
            <video
              controls
              style={{ height: "100%", width: "100%", borderRadius: "5px" }}
            >
              <source src={tempUrl} type="video/mp4"></source>
            </video>
          )}
        </div>
      ) : (
        <div
          className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 upload-image"
          onClick={handleClick}
        >
          <div className="fs-6 fw-bolder text-gray-700 text-center p-10">
            + Add Image
          </div>
        </div>
      )}
      {}
    </>
  );
};
