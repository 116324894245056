export interface IPermitType {
  name: string;
  description: string;
  id?: number;
}

export const permitTypeInitialValues: IPermitType = {
  name: "",
  description: "",
};
