import { createSlice } from "@reduxjs/toolkit";

interface IOrganization {
  name: string;
  description: string;
  id?: number;
}

interface IOrganizationState {
  organization: IOrganization[];
  isLoading: boolean;
  error: string | null;
  success: boolean | null;
}

const initialState: IOrganizationState = {
  organization: [],
  isLoading: false,
  error: null,
  success: false,
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    replaceOrganization: (state, action) => {
      state.isLoading = false;
      state.organization = action.payload;
      state.error = null;
      state.success = true;
    },
    addOrganization: (state, action) => {
      state.isLoading = false;
      state.organization = [...state.organization, action.payload];
      state.error = null;
      state.success = true;
    },
    removeOrganization: (state, action) => {
      state.isLoading = false;
      state.organization = state.organization.filter(
        (org: any) => org.id !== action.payload
      );
      state.error = null;
      state.success = true;
    },
    loading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: {},
});

export const { loading, replaceOrganization, removeOrganization } =
organizationSlice.actions;

export default organizationSlice.reducer;
