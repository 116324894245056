import * as dayjs from "dayjs";

class CoordinateUtilsService {
  private static instance: CoordinateUtilsService;

  private constructor() {}

  public static getInstance(): CoordinateUtilsService {
    if (!CoordinateUtilsService.instance) {
      CoordinateUtilsService.instance = new CoordinateUtilsService();
    }

    return CoordinateUtilsService.instance;
  }

  extractCoordinateFromMetadata(metadata: any) {
    if (Object.keys(metadata).length === 0)
      return {
        latitude: "N/A",
        longitude: "N/A",
      };
    const gpsLatitude = metadata["GPSLatitude"];
    const gpsLatitudeRef = metadata["GPSLatitudeRef"];
    const gpsLongitude = metadata["GPSLongitude"];
    const gpsLongitudeRef = metadata["GPSLongitudeRef"];
    const latDegrees =
      gpsLatitude.length > 0 ? this._gpsToNum(gpsLatitude[0]) : 0;
    const latMinutes =
      gpsLatitude.length > 1 ? this._gpsToNum(gpsLatitude[1]) : 0;
    const latSeconds =
      gpsLatitude.length > 2 ? this._gpsToNum(gpsLatitude[2]) : 0;
    const lonDegrees =
      gpsLatitude.length > 0 ? this._gpsToNum(gpsLongitude[0]) : 0;
    const lonMinutes =
      gpsLatitude.length > 1 ? this._gpsToNum(gpsLongitude[1]) : 0;
    const lonSeconds =
      gpsLatitude.length > 2 ? this._gpsToNum(gpsLongitude[2]) : 0;
    const latDirection =
      gpsLatitudeRef == "W" || gpsLatitudeRef == "S" ? -1 : 1;
    const lonDirection =
      gpsLongitudeRef == "W" || gpsLongitudeRef == "S" ? -1 : 1;
    const latitude =
      latDirection * (latDegrees + latMinutes / 60 + latSeconds / (60 * 60));
    const longitude =
      lonDirection * (lonDegrees + lonMinutes / 60 + lonSeconds / (60 * 60));
    return {
      latitude,
      longitude,
    };
  }

  private _gpsToNum(coordinate: any) {
    if (coordinate && coordinate.numerator && coordinate.denominator)
      return Number(coordinate.numerator) / Number(coordinate.denominator);
    else return 0;
  }
}

export const CoordinateUtilsServiceSingleton =
  CoordinateUtilsService.getInstance();
