/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { DropPointForm } from "./DropPointForm";

type Props = {
  show: boolean;
  handleClose: () => void;
  onConfirm: (data: any) => {};
  ticketId: number;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const DropPointModal = ({ show, handleClose, onConfirm, ticketId }: Props) => {
  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <DropPointForm
        show={show}
        onConfirm={onConfirm}
        ticketId={ticketId}
        onClose={handleClose}
      />
    </Modal>,
    modalsRoot
  );
};

export { DropPointModal };
