import { combineReducers, configureStore } from "@reduxjs/toolkit";
import globalReducer from "./features/global/global";
import zonesReducer from "./features/zones/zones";
import districtReducer from "./features/district/district";
import statesReducer from "./features/states/state";
import rolesReducer from "./features/roles/roles";
import organizationReducer from "./features/organization/organization";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { IUser } from "./app/modules/user/UserModel";

interface IStore {
  global?: IUser;
}

const persistConfig = {
  key: "reducer",
  version: 1,
  whitelist: ["global"],
  storage,
};

const reducer = combineReducers({
  global: globalReducer,
  zones: zonesReducer,
  district: districtReducer,
  states: statesReducer,
  roles: rolesReducer,
  organizations: organizationReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
